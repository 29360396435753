import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form";
import { Rules } from "../../types/integrations";

interface SwitchProps {
  label: string;
  name: string;
  rules: Rules;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  hidden?: boolean;
}

export const FormSwitch = ({
  label,
  name,
  register,
  rules,
  errors,
  hidden,
}: SwitchProps) => {
  return (
    <FormControl hidden={hidden} isInvalid={!!errors[name]}>
      <FormLabel fontSize="lg" fontWeight="bold">
        {label}
      </FormLabel>
      <HStack align="start" justify="start" gap={1}>
        <Switch
          {...register(name, {
            ...rules,
            setValueAs: (v: boolean) => v,
          })}
        />
        <Text>{name}</Text>
      </HStack>
      <FormErrorMessage>{`${errors[name]?.message}`}</FormErrorMessage>
    </FormControl>
  );
};
